import React, { useState, useEffect } from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import axiosInstance from "../Utils/Utils";
import styles from "./All.module.css";

interface Member {
  member_id: number;
  member_username: string;
  member_email: string;
  member_role: string;
}

interface Project {
  project_id: number;
  project_name: string;
  members: Member[];
}

const All: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const apiURL = process.env.REACT_APP_API_URL || "";

  const fetchProjects = async () => {
    try {
      const response = await axiosInstance.get(`${apiURL}/api/projects-members/`);
      if (response.data.isOk && response.data.code === "SUCCESS") {
        setProjects(response.data.projects);
      }
    } catch (error) {
      console.error("Error fetching projects", error);
    }
  };

  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetchProjects();
  }, [apiURL]);

  const handleEdit = (id: number) => {
    console.log("Edit project", id);
    // Add your edit logic here
  };

  const handleClone = async (project_id: number) => {
    try {
      const response = await axiosInstance.post("/api/duplicate-project/", { project_id });
      if (response.data.isOk) {
        console.log("Project cloned successfully");
        // Refresh the project list after cloning
        fetchProjects();
      } else {
        console.error("Error cloning project:", response.data.message);
      }
    } catch (error) {
      console.error("Error cloning project:", error);
    }
  };

  return (
    <TableContainer component={Paper} className={styles.tableContainer}>
      <Table className={styles.table}>
        <TableHead className={styles.tableHead}>
          <TableRow>
            <TableCell className={styles.tableHeadCell}>Projects</TableCell>
            <TableCell className={styles.tableHeadCell}>Members</TableCell>
            <TableCell className={styles.tableHeadCell}>Edit</TableCell>
            <TableCell className={styles.tableHeadCell}>Clone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map((project) => (
            <TableRow key={project.project_id} className={styles.tableBodyRow}>
              <TableCell className={styles.tableCell} data-label="Projects">
                {project.project_name}
              </TableCell>
              <TableCell className={styles.tableCell} data-label="Members">
                {project.members.map((member) => member.member_username).join(", ")}
              </TableCell>
              <TableCell className={styles.tableCell} data-label="Edit">
                <Button
                  variant="contained"
                  className={styles.editButton}
                  onClick={() => handleEdit(project.project_id)}
                >
                  Edit
                </Button>
              </TableCell>
              <TableCell className={styles.tableCell} data-label="Clone">
                <Button
                  variant="contained"
                  className={styles.cloneButton}
                  onClick={() => handleClone(project.project_id)}
                >
                  Clone
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default All;
